import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import footerLogo from '../assets/images/INSUREGORA-LOGO-2.svg'
import LocationIcon from '../assets/images/location.svg'
import PhoneIcon from '../assets/images/phone.svg'
import EmailIcon from '../assets/images/email.svg'
import facebook from '../assets/images/facebook.svg'
import twitter from '../assets/images/twitter.svg'
import instagram from '../assets/images/instagram.svg'
import linkedin from '../assets/images/linkedin.svg'
import youtube from '../assets/images/youtube.svg'
import footerBg from '../assets/images/footer-bg.png'
import CookieConsent from './CookieConsent';
function Footer(props) {
  const currentYear=new Date().getFullYear();
  const openContactForm = () => {
    const linkGetFreeQuote = document.getElementById('getFreeQuote');
    if (linkGetFreeQuote) {
      linkGetFreeQuote.click();
    }
  };

  const stringToSlug = (str) => {
      return str
          .toLowerCase() // Convert to lowercase
          .trim() // Trim leading and trailing whitespace
          .replace(/[^\w\s-]/g, '') // Remove all non-word characters (letters, numbers, underscores)
          .replace(/[\s_-]+/g, '-') // Replace spaces and underscores with a hyphen
          .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
  };

  // Function to parse JSON and return list items
  const renderLinks = (dataLinks, footerBtnClass="") => {
    if (!dataLinks) {
        return null;
    }
    try {
        // Return the list items
        return dataLinks.map((row, index) => (
            <li key={index} className={footerBtnClass+'-'+stringToSlug(row.title)}>
                <a href={row.url}>{row.title}</a>
            </li>
        ));
    } catch (error) {
        console.error("Error parsing JSON:", error);
    }
  };

  
  const handleFindForm = () => {
    alert(1)
  }

  return (
    <footer>
      {/* <img className='footer-bg' src={footerBg} alt="" /> */}
        <Row className='footer-top'>
            <Col sm={6} lg={4}>
              <div className="footer-box">
                <h3>Our Services <span></span></h3>
                <ul>
                  {renderLinks(props.setingData.our_service_links, 'footer-btn footer-btn')}
                </ul>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div className="footer-box">
                <h3>Quick Links <span></span></h3>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><a href="/about">About Us</a></li>
                  <li><a href="/articles">Articles</a></li>
                  <li><a href="/career">Career</a></li>
                  <li><p className='contact-page-link' onClick={openContactForm}>Contact Us</p></li>
                </ul>
              </div>
            </Col>
            <Col sm={12} lg={4}>
              <div className="footer-box support-box">
                <h3>Support <span></span></h3>
                <ul>
                  {renderLinks(props.setingData.support_links)}
                  <li class="-find-form"><p className='contact-page-link' onClick={openContactForm}>Find Form</p></li>
                </ul>
              </div>
            </Col>
        </Row>
        <Row className='footer-main'>
          <Col md={6} lg={4}>
            <div className='footer-logo'>
              <a href="">
                <img src={footerLogo} alt="" />
              </a>
              <p>{props.setingData.footer_about_us}</p>
            </div>
          </Col>
          <Col sm={6} lg={4}>
            <div className="footer-box">
              <h3>Contact us <span></span></h3>
              <div className='footer-info'>
                <p><span><img src={LocationIcon} alt="" /></span>{props.setingData.footer_address}</p>

                <div className='phone-email'>
                  <a href={`tel:${props.setingData.office_phone_no}`}><span><img src={PhoneIcon} alt="" /></span>{props.setingData.office_phone_no}</a>
                </div>
                <div className='phone-email'>
                  <a href={`mailto:${props.setingData.info_email}`}><span><img src={EmailIcon} alt="" /></span>{props.setingData.info_email}</a>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="footer-box social-media-box">
              <h3>Contact us <span></span></h3>
              <ul className='social-media'>
                <li><a href={props.setingData.facebook_url} target="_blank"><img src={facebook} alt="" /></a></li>
                <li><a href={props.setingData.twitter_url} target="_blank"><img src={twitter} alt="" /></a></li>
                <li><a href={props.setingData.linkedin_url} target="_blank"><img src={linkedin} alt="" /></a></li>
                <li><a href={props.setingData.instagram_url} target="_blank"><img src={instagram} alt="" /></a></li>
                <li><a href={props.setingData.youtube_url} target="_blank"><img src={youtube} alt="" /></a></li>
              </ul>
            </div>
          </Col>          
        </Row>
        <Row className='footer-bottom'>
          <Col>
            <p>Copyright © {currentYear} <a href="">{props.setingData.comapany_name}</a>. All Rights Reserved.</p>
          </Col>
        </Row>
        <CookieConsent />
    </footer>
    
  )
}

export default Footer